import { App, Button, Form, Modal, Slider, SliderSingleProps } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { EnvironmentJSON } from "../../types/environment/environment.type";
import { suspendEnvironmentHibernationMutation } from "../../providers/graphql/mutations/suspendEnvironmentHibernation.mutation";


const marks: SliderSingleProps['marks'] = {
  1: '1 H',
  2: '2 H',
  3: '3 H',
  4: '4 H',
  5: '5 H',
};

type SuspendEnvironmentHibernationFormFields = {
  suspendHibernationHours?: number;
};

export type SuspendEnvironmentHibernationFormProps = {
  environment: EnvironmentJSON;
  onRefetch: () => void;
};

export const SuspendEnvironmentHibernationForm = ({
  environment,
  onRefetch,
}: SuspendEnvironmentHibernationFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    suspendEnvironmentHibernationMutation()
  );

  const onSubmit = async (
    values: SuspendEnvironmentHibernationFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      suspendEnvironmentHibernationInput: {
        environmentId: environment.environmentId,
        suspendHibernationHours: values.suspendHibernationHours
      },
    });

    if (error) {
      return;
    }

    message.info("Environment hibernation set to suspended successfully");

    onRefetch();

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
        initialValues={{
          suspendHibernationHours: 3,
        }}
      >
        <Form.Item
          label="Select Duration for Hibernation Suspension (in Hours)"
          labelCol={{ span: 24 }}
          name="suspendHibernationHours"
          rules={[
            {
              required: true,
              message: "Please select the suspension duration",
            },
          ]}
        >
          <Slider
            min={1}
            max={5}
            marks={marks}
            defaultValue={3}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Suspend Hibernation
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
